.welcome-back-page {
    display: flex;
    flex-direction: column;

    @mixin md {
      flex-direction: row-reverse;
    }

    .login {
      background: #fff;
      display: flex;
      flex: 1;
      width: 100%;
      padding: 7px 20px 5px;
      justify-content: center;

      @mixin sm {
        padding-top: 10px;
        padding-bottom: 28px;
      }

      @mixin md {
        padding-top: 80px;
      }

      & > div {
        width: 100%;
        position: relative;

        @mixin sm {
          width: 375px;
        }

        @mixin md {
          width: 383px;
        }
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      flex: 1;

      .light,
      .dark,
      .dark-green {
        display: flex;
        flex: 1;
        min-height: 314px;
        width: 100%;

        @mixin sm {
          min-height: 314px;
        }

        @mixin md {
          min-height: 370px;
        }

        @mixin lg {
          min-height: 403px;
        }

        @mixin xl {
          min-height: 433px;
        }
      }

      .light {
        background: #EFF9F4;
        padding-left: 45%;

        @mixin sm {
          padding-left: 40%;
        }

        @mixin md {
          padding-left: 50%;
        }

        @mixin xl {
          padding-left: 60%;
        }

        h3 {
          @mixin h1;
          text-transform: none;
          margin: 0 0 14px;

          @mixin sm {
            @mixin h3;
          }
        }

        ul {
          list-style: none;
          padding-left: 0;
          padding-right: 30px;

          li {
            @mixin paragraphMediumLessHeight;
            margin-bottom: 12px;
            color: #8C969E;
            position: relative;
            left: 25px;

            svg {
              color: #7BC4AB;
              height: 18px;
              top: 3px;
              left: -28px;
              position: absolute;
            }
          }
        }

        & > div {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;

          img {
            position: absolute;
            left: -180px;
            width: 173px;
            height: 167px;
            margin-top: 50%;
            top: -60px;

            @mixin sm {
              width: 207px;
              height: 201px;
              left: -220px;
              top: -110px;
            }

            @mixin md {
              width: 196px;
              height: 190px;
              left: -206px;
              top: -70px;
            }

            @mixin xl {
              width: 278px;
              height: 287px;
              left: -296px;
              top: -100px;
            }
          }
        }
      }

      .dark {
        background: #92B1A5;
        color: #fff;
        flex-direction: column;
        padding: 67px 20px 60px;
        align-items: center;

        @mixin md {
          padding: 121px 119px 127px 162px;
        }

        & > div {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          position: relative;

          @mixin sm {
            width: 476px;
          }

          p {
            @mixin paragraphLessHeight;
            position: relative;
            z-index: 1;
          }

          img {
            width: 58px;
            height: 58px;
            position: relative;
            z-index: 1;

            @mixin sm {
              width: 71px;
              height: 71px;
            }

            @mixin md {
              width: 74px;
              height: 74px;
            }

            @mixin xl {
              width: 102px;
              height: 102px;
            }
          }

          &:first-of-type {
            margin-bottom: 8px;

            @mixin xl {
              margin-bottom: 18px;
            }

            img {
              margin-right: 15px;
            }
          }

          &:last-of-type {
            img {
              margin-left: 15px;
            }
          }
        }

        .quote {
          position: absolute;
          font-family: 'Abhaya Libre', serif;
          font-style: normal;
          font-weight: 600;
          left: -25px;
          top: 220px;
          color: #9db9ae;
          font-size: 650px;
          z-index: 0;

          @mixin md {
            left: -60px;
            font-size: 1000px;
            top: 330px;
          }
        }
      }



      .dark-green {
        background-color: #256376;
        padding-left: 45%;

        @mixin sm {
          padding-left: 40%;
        }

        @mixin md {
          padding-left: 50%;
        }

        @mixin xl {
          padding-left: 60%;
        }

        h3 {
          @mixin h1;
          color: #FFEA79;
          font-family: "Abhaya Libre", serif;
          text-transform: none;
          text-align: center;
          font-style: normal;
          font-weight: bold;
          text-align: center;
          font-size: 42px;
          line-height: 49px;
          margin-block-start: 0px;
          margin-block-end: 0px;

          @mixin sm {
            @mixin h3;
          }
        }

        .coupon-code {
          color: #FFFFFF;
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 22px;
          line-height: 32px;
          align-items: center;
          text-align: center;
        }

        .end-date {
          color: #89C4D6;
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 22px;
          line-height: 32px;
          align-items: center;
          margin-bottom: 10px;
          text-align: center;
        }

        & > div {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;

          img {
            position: absolute;
            left: -180px;
            width: 173px;
            height: 167px;
            margin-top: 50%;
            top: -60px;

            @mixin md {
              width: 316px;
              height: 317.1px;
              left: -310px;
              top: -70px;
            }

            @mixin xl {
              width: 358.02px;
              height: 359.27px;
              left: -370px;
              top: -80px;
            }
          }
        }

        .discount-circle {
          background-image: url("https://www.provenskincare.com/cdn-cgi/image/f=auto,onerror=redirect/https://media.provenskincare.com/img/landing/070521login-lp-desktop-stars.png");
          background-size: contain;
          background-repeat: no-repeat;
          text-align: center;
          align-items: center;
          margin-left: auto;
          margin-right: auto;
          width: 190px;
          height: 190px;
          position: relative;
          display: flex;
          justify-content: center;

          .discount-wrapper {
            margin: auto;
            color: #FFEA79;
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: normal;
            text-align: center;

            .discount-price {
              font-size: 30px;
              line-height: 40px;
              white-space: pre;
            }
            .discount-price-usd {
              font-size: 18px;
              line-height: 28px;
            }
            .full-price-crossed {
              font-size: 18px;
              line-height: 28px;
              text-decoration: line-through;
              white-space: pre;
            }
          }
        }
      }
    }
  }
