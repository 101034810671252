

.socialShare {
    display: flex;
    .shareLink {
        display: block;
        width: 30px;
        height: 30px;
        background-size: 100%;
        background-repeat: no-repeat;
        font-size: 0;
        margin-right: 10px;

        &.twitter {
            background-image: url(https://dl7bo1dy930sf.cloudfront.net/img/twitter-sharing-icon.svg);
        }

        &.facebook {
            background-image: url(https://dl7bo1dy930sf.cloudfront.net/img/facebook-sharing-icon.svg);
        }

        &.linkedin {
            background-image: url(https://dl7bo1dy930sf.cloudfront.net/img/linkedin-sharing-icon.svg);
        }
    }
}