.promo-offer {
  background-color: #256376;
  text-align: center;
  position: relative;

  @mixin xs {
    width: 336px;
    height: 87px;
    margin-bottom: 10px;
  }
  @mixin sm {
    width: 402px;
    height: 87px;
    margin-bottom: 15px;
  }

  .promo-wrapper {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);

    .tag-line {
      font-family: "Abhaya Libre", serif;
      font-style: normal;
      font-weight: 500;

      color: #FFEA79;
      @mixin xs {
        font-size: 44px;
        line-height: 44px;
      }
      @mixin sm {
        font-size: 55px;
        line-height: 55px;
      }
    }
    .sub-text {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;

      .code {
        color: #FFFFFF;
      }
      .deadline {
        color: #89C4D6;
      }
    }
  }
}
