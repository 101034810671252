@font-face {
  font-family: "Inter";
  font-weight: 100 900;
  font-style: normal;
  src: url("../styles/fonts/Inter-VariableFont_slnt_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 100 900;
  font-style: italic;
  src: url("../styles/fonts/Inter-VariableFont_slnt_wght.ttf") format("truetype");
}

@font-face {
  font-family:"Freight Big Comp Pro";
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  src:url("../styles/fonts/Freight_Big_Comp_Pro_Book.woff2") format("woff2"),
      url("../styles/fonts/Freight_Big_Comp_Pro_Book.woff") format("woff"),
      url("../styles/fonts/Freight_Big_Comp_Pro_Book.otf") format("opentype");
  font-display: auto;
}

@font-face {
  font-family:"Freight Big Comp Pro";
  font-weight: 400;
  font-style: italic;
  font-display: auto;
  font-stretch: normal;
  src:url("../styles/fonts/Freight_Big_Comp_Pro_Book_Italic.woff2") format("woff2"),
      url("../styles/fonts/Freight_Big_Comp_Pro_Book_Italic.woff") format("woff"),
      url("../styles/fonts/Freight_Big_Comp_Pro_Book_Italic.otf") format("opentype");
}

